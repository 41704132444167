<template>
	<modal-wrapper @close="$emit('close')" size="medium">
		<div class="modal-body-files">
			<h2>Auftragsdateien</h2>
			<section class="buttons">
				<button class="button" @click.prevent="downloadAll">
					<unicon name="download-alt" icon-style="monochrome" height="24" />
					<p>Herunterladen</p>
				</button>
				<button
					v-if="$store.getters.getAppMode !== 'doctor'"
					class="button"
					:disabled="!$store.getters.isOwlLabOnline"
					@click="sendToOwlLab"
				>
					<div class="unicon">
						<img src="@/assets/img/owllab.png" />
					</div>
					<p>An OwlLab senden</p>
				</button>
			</section>
			<section class="file-table">
				<table class="table">
					<thead>
						<th>Datei</th>
						<th>Größe</th>
						<th></th>
					</thead>
					<tbody>
						<tr v-for="file in files" :key="file.UUID">
							<td>{{ file.name | truncate }}</td>
							<td>{{ file.size | toMB }}</td>
							<td>
								<a class="option" @click="downloadFile(file.UUID)">
									<unicon
										name="download-alt"
										icon-style="monochrome"
										height="24"
										width="24"
									/>
								</a>
							</td>
						</tr>
					</tbody>
				</table>
			</section>
			<div class="overlay" v-if="loading">
				<div class="overlay__inner">
					<div class="overlay__content"><span class="spinner"></span></div>
				</div>
			</div>
		</div>
	</modal-wrapper>
</template>

<script>
import formatMixin from '@/mixins/format.mixin';
import cryptoMixin from '@/mixins/crypto.mixin';
import streamSaver from 'streamsaver';
streamSaver.mitm =
	process.env.NODE_ENV.trim() === 'development'
		? 'http://localhost:1234/mitm.html'
		: '/mitm.html';
import Socket from '@/plugins/socket';
import Swal from 'sweetalert2';
export default {
	name: 'FilesModal',
	props: ['record'],
	components: {
		modalWrapper: () => import('../modal-wrapper.vue')
	},
	mixins: [formatMixin, cryptoMixin],
	filters: {
		truncate: function (str) {
			const width = Math.max(
				document.documentElement.clientWidth || 0,
				window.innerWidth || 0
			);
			const n = width > 1100 ? 30 : 15;
			return str.length > n ? str.substr(0, n - 1) + '...' : str;
		}
	},
	data() {
		return {
			files: [],
			filestream: null,
			writer: null,
			fileData: null,
			loading: false
		};
	},
	watch: {
		record: function (new_val) {
			this.loadFiles(new_val.UUID);
		}
	},
	mounted() {
		this.loadFiles(this.record.UUID);
	},
	methods: {
		async downloadAll() {
			for (let file of this.files) {
				await this.downloadFile(file.UUID);
			}
		},
		async loadFiles(recordID) {
			const fileListResponse = await this.$api.post(
				'/user/get_file_list',
				{ record: recordID },
				{
					headers: {
						Authorization: `Bearer ${this.$store.getters.getUserToken}`
					}
				}
			);
			this.files = fileListResponse.data;
		},
		async downloadFile(UUID) {
			this.loading = true;
			let fileInfoResponse = await this.$api.post(
				'/user/get_file_info',
				{
					file_id: UUID
				},
				{
					headers: {
						Authorization: `Bearer ${this.$store.getters.getUserToken}`
					}
				}
			);
			let filestream = streamSaver.createWriteStream(
				fileInfoResponse.data.name,
				{
					size: fileInfoResponse.data.size
				}
			);
			let writer = await filestream.getWriter();
			this.fileData = fileInfoResponse.data;
			await this.loadFile(UUID, writer);
		},
		async loadFile(UUID, writer) {
			let that = this;
			let done = false;
			let index = 0;
			let isLegacy = false;
			let readableStream;
			readableStream = new ReadableStream({
				start(ctrl) {
					const nextChunk = async () => {
						let fileDataResponse = await that.$api.post(
							'/user/get_file',
							{
								file_id: UUID,
								chunk_index: index
							},
							{
								headers: {
									Authorization: `Bearer ${that.$store.getters.getUserToken}`
								}
							}
						);
						done =
							fileDataResponse.data.length - 1 <=
							fileDataResponse.data.current_index;
						if (fileDataResponse.data.data) {
							isLegacy = fileDataResponse.data.legacy;
							let data;
							if (isLegacy) {
								const buf = Buffer.from(fileDataResponse.data.data).toString();
								data = await that.decryptData(buf, isLegacy);
							} else {
								data = await that.decryptData(
									fileDataResponse.data.data,
									isLegacy
								);
							}
							ctrl.enqueue(data);
						}

						if (!done) {
							index += 1;
							nextChunk();
						} else {
							ctrl.close();
						}
					};
					nextChunk();
				}
			});
			const reader = readableStream.getReader();
			const close = () => {
				writer.close();
			};
			const pump = () =>
				reader.read().then((res) => {
					if (!res.done) {
						if (isLegacy) {
							writer.abort();
							const byte = this.base64ToArrayBuffer(this.ab2str(res.value));
							let blob = new Blob([byte], { type: this.fileData.type });
							let link = document.createElement('a');
							link.href = window.URL.createObjectURL(blob);
							link.download = this.fileData.name;
							link.click();
							close();
							that.loading = false;
							return true;
						} else {
							writer.write(new Uint8Array(res.value)).then(pump);
						}
					} else {
						close();
						that.loading = false;
						return true;
					}
				});
			pump();
		},
		async decryptData(data, legacy) {
			const aes =
				this.$store.getters.getAppMode === 'lab'
					? this.record.aes_key_lab
					: this.$store.getters.getAppMode === 'doctor'
					? this.record.aes_key_doc
					: null;
			const priv = await this.$store.getters.getPrivateKey;
			const decryptkey = await this.decryptAESKey(this.str2ab(atob(aes)), priv);
			const iv = new Uint8Array(Object.values(JSON.parse(this.record.iv)));
			let arrayBuffer;
			if (legacy) {
				arrayBuffer = this.str2ab(atob(data));
			} else {
				arrayBuffer = this.str2ab(data);
			}
			let ov = await this.decryptFileAES(iv, arrayBuffer, decryptkey);
			arrayBuffer = null;
			return ov;
		},
		base64ToArrayBuffer(base64) {
			let binaryLen = base64.length;
			let bytes = new Uint8Array(binaryLen);
			for (let i = 0; i < binaryLen; i++) {
				bytes[i] = base64.charCodeAt(i);
			}
			return bytes;
		},
		sendToOwlLab() {
			const sendObject = {
				type: 'record',
				docName: this.record.doctor.name,
				patientData: this.record.extended.patient,
				auftrag: this.record.extended.order,
				schema: this.record.extended.schema,
				id: this.record.UUID
			};
			const owllabSettings = this.$store.getters.getOwlLab.settings;
			const socket = Socket.getSocket(owllabSettings.url, owllabSettings.port);
			socket.send(JSON.stringify(sendObject));
			for (let file of this.files) {
				let that = this;
				let done = false;
				let index = 0;
				let total = 0;
				let readableStream;
				readableStream = new ReadableStream({
					start(ctrl) {
						const nextChunk = async () => {
							let fileDataResponse = await that.$api.post(
								'/user/get_file',
								{
									file_id: file.UUID,
									chunk_index: index
								},
								{
									headers: {
										Authorization: `Bearer ${that.$store.getters.getUserToken}`
									}
								}
							);
							done =
								fileDataResponse.data.length - 1 <=
								fileDataResponse.data.current_index;
							total = fileDataResponse.data.length;
							if (fileDataResponse.data.data) {
								let data = await that.decryptData(fileDataResponse.data.data);
								ctrl.enqueue(data);
							}

							if (!done) {
								index += 1;
								nextChunk();
							} else {
								index += 1;
								ctrl.close();
							}
						};
						nextChunk();
					}
				});
				const reader = readableStream.getReader();
				const pump = () =>
					reader.read().then((res) => {
						if (!res.done) {
							const fileObject = {
								type: 'file',
								id: file.UUID,
								data: this.base64ArrayBuffer(res.value),
								item: index,
								total: total,
								name: file.name
							};
							socket.send(JSON.stringify(fileObject));
							pump();
						}
					});
				pump();
			}
		},
		base64ArrayBuffer(arrayBuffer) {
			let base64 = '';
			const encodings =
				'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789+/';

			const bytes = new Uint8Array(arrayBuffer);
			const byteLength = bytes.byteLength;
			const byteRemainder = byteLength % 3;
			const mainLength = byteLength - byteRemainder;

			let a;
			let b;
			let c;
			let d;
			let chunk;

			// Main loop deals with bytes in chunks of 3
			for (let i = 0; i < mainLength; i += 3) {
				// Combine the three bytes into a single integer
				chunk = (bytes[i] << 16) | (bytes[i + 1] << 8) | bytes[i + 2];

				// Use bitmasks to extract 6-bit segments from the triplet
				a = (chunk & 16515072) >> 18; // 16515072 = (2^6 - 1) << 18
				b = (chunk & 258048) >> 12; // 258048   = (2^6 - 1) << 12
				c = (chunk & 4032) >> 6; // 4032     = (2^6 - 1) << 6
				d = chunk & 63; // 63       = 2^6 - 1

				// Convert the raw binary segments to the appropriate ASCII encoding
				base64 += encodings[a] + encodings[b] + encodings[c] + encodings[d];
			}

			// Deal with the remaining bytes and padding
			if (byteRemainder === 1) {
				chunk = bytes[mainLength];

				a = (chunk & 252) >> 2; // 252 = (2^6 - 1) << 2

				// Set the 4 least significant bits to zero
				b = (chunk & 3) << 4; // 3   = 2^2 - 1

				base64 += `${encodings[a]}${encodings[b]}==`;
			} else if (byteRemainder === 2) {
				chunk = (bytes[mainLength] << 8) | bytes[mainLength + 1];

				a = (chunk & 64512) >> 10; // 64512 = (2^6 - 1) << 10
				b = (chunk & 1008) >> 4; // 1008  = (2^6 - 1) << 4

				// Set the 2 least significant bits to zero
				c = (chunk & 15) << 2; // 15    = 2^4 - 1

				base64 += `${encodings[a]}${encodings[b]}${encodings[c]}=`;
			}

			return base64;
		}
	}
};
</script>

<style lang="scss">
.modal-body-files {
	display: block;
	padding: 1rem;
	display: grid;
	grid-template-rows: 1fr 1fr auto;
	.buttons {
		width: 100%;
		display: flex;
		justify-content: flex-end;
		gap: 1rem;
		.button {
			display: flex;
			justify-content: center;
			align-items: center;
			.unicon {
				margin-right: 5px;
			}
		}
	}
	.file-table {
		.table {
			width: 100%;
		}
	}
}
.overlay {
	left: 0;
	top: 0;
	width: 100%;
	height: 100%;
	position: fixed;
	background: #222222aa;
}

.overlay__inner {
	left: 0;
	top: 0;
	width: 100%;
	height: 100%;
	position: absolute;
}

.overlay__content {
	left: 50%;
	position: absolute;
	top: 50%;
	transform: translate(-50%, -50%);
}

.spinner {
	width: 75px;
	height: 75px;
	display: inline-block;
	border-width: 2px;
	border-color: rgba(255, 255, 255, 0.05);
	border-top-color: #fff;
	animation: spin 1s infinite linear;
	border-radius: 100%;
	border-style: solid;
}

@keyframes spin {
	100% {
		transform: rotate(360deg);
	}
}
</style>
